// src/HomePage.js
import React, { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import Home from '../components/Home';
import HeaderHome from '../components/HeaderHome';
import LoginForm from '../components/LoginForm';
import Signup from '../components/Signup';
import VerticalNavBar from '../components/Menubar';
import Header from '../components/Header';
import VoiceBot from '../components/VoiceBot';
import File from '../components/File';

export default function HomePage() {
  const { activeComponent, changeComponent } = useContext(AppContext);
  const token = localStorage.getItem('accessToken');

  return (
    <>
      {!token && (
        <>
          <HeaderHome changeComponent={changeComponent} activeComponent={activeComponent} />
          {activeComponent === 'Home' && <Home changeComponent={changeComponent} />}
          {activeComponent === 'login' && <LoginForm changeComponent={changeComponent} />}
          {activeComponent === 'signup' && <Signup changeComponent={changeComponent} />}
        </>
      )}
      {token && (
        <div className="app-container">
          <VerticalNavBar activeComponent={activeComponent} changeComponent={changeComponent} />
          <div className="content">
            <Header changeComponent={changeComponent} />
            {activeComponent === 'Home' && <Home changeComponent={changeComponent} token={token} />}
            {activeComponent === 'Dashboard' && <VoiceBot changeComponent={changeComponent} />}
            {activeComponent === 'File' && <File changeComponent={changeComponent} />}
          </div>
        </div>
      )}
    </>
  );
}
