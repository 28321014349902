import React, { useEffect, useRef, useState } from 'react';

const ResultsDisplay = ({ results, stopPlayingAudio, handleMainError, handlestartplaying, handelstopplaying }) => {
  const audioRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [iserror, setIsError] = useState(false)

  useEffect(() => {

    if(results.error){
      setIsError(true)
      if(handleMainError){
        handleMainError()
      }
    }

    const audioElement = audioRef.current;
    if (results.links.length > 0 && audioElement) {
      const handlePlay = () => {
        // console.log(`Playing audio at index: ${currentIndex}`);
        setIsPlaying(true);
        handlestartplaying()
      };

      const handleEnded = () => {
        if (currentIndex < results.links.length - 1) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          setIsPlaying(false); // Audio has finished playing all items
          handelstopplaying()
        }
      };

      const handleError = (error) => {
        console.error(`Error playing audio at index ${currentIndex}:`, error);
        if (currentIndex === 1) {
          setIsError(true)
          if(handleMainError){
            handleMainError()
          }
        }
        setIsPlaying(false);
        handelstopplaying();
      };
      

      const playAudio = () => {
        if (currentIndex < 0) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
        if (currentIndex < 200) {
          if (results.links[currentIndex]) {
            audioElement.src = results.links[currentIndex];
            audioElement.load();
          }
        }
        audioElement.play().then(handlePlay).catch(handleError);
      };

      playAudio();

      audioElement.addEventListener('ended', handleEnded);

      return () => {
        audioElement.removeEventListener('ended', handleEnded);
        if (audioElement) {
          audioElement.pause();
          audioElement.currentTime = 0;
          audioElement.src = '';
        }
      };
    }
  }, [currentIndex, results]);

  useEffect(() => {
    if (stopPlayingAudio && audioRef.current) {
      stopAudio();
    }
  }, [stopPlayingAudio]);

  const stopAudio = () => {
    const audioElement = audioRef.current;
    if (audioElement && !audioElement.paused) {
      audioElement.pause();
      audioElement.currentTime = 0;
      audioElement.src = '';
      setIsPlaying(false);
      handelstopplaying()
    }
  };

  const handleReplay = () => {
    stopAudio();
    setTimeout(() => {
      setCurrentIndex(-1);
    }, 100);
  };

  const handleButtonClick = () => {
    if (isPlaying) {
      stopAudio();
    } else {
      handleReplay();
    }
  };

  const onToggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <div className="results">
      <div className="result-item">
        <audio ref={audioRef} style={{ display: 'none' }} />
      </div>
      <div className="toggle-button">
        <button onClick={handleButtonClick}
          // className={iserror? 'hidden' :isPlaying ? 'stop_result_button' : 'replay_result_button'}
          className='hidden'

          >
          {isPlaying ? <i className="fas fa-stop"></i> : <i className="fas fa-redo"></i>}
        </button>
      </div>
      <div>
        </div> 
      {/* <div className="toggle-button">
        <button onClick={onToggle}>
          {isExpanded ? 'Hide Text' : 'Show Text'}
        </button>
      </div> */}
      {/* <div className={`data ${isExpanded ? '' : 'hidden'}`}>
        <div className="result-item">
          <p>{results.llmText}</p>
        </div>
      </div> */}
    </div>
  );
};

export default ResultsDisplay;
