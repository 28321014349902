import React, { useState, useEffect } from 'react';

const History = ({ chatHistory, latestIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  // Update the current index when the latestIndex changes
  useEffect(() => {
    setCurrentIndex(latestIndex);
    setIsExpanded(false); // Reset expansion when a new entry is added
  }, [latestIndex, chatHistory]);

  const handleNext = () => {
    if (currentIndex < chatHistory.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setIsExpanded(false);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setIsExpanded(false);
    }
  };

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const renderText = (text) => {
    const wordLimit = 20;
    const words = text.split(' ');

    if (words.length <= wordLimit || isExpanded) {
      return (
        <>
          {text}
          {words.length > wordLimit && (
            <button onClick={toggleReadMore} className="read-more-btn">
              Show Less
            </button>
          )}
        </>
      );
    }

    return (
      <>
        {words.slice(0, wordLimit).join(' ')}...
        <button onClick={toggleReadMore} className="read-more-btn">
          Read More
        </button>
      </>
    );
  };

  return (
    <div className="history-container">
      {chatHistory.length > 0 ? (
        <>
          <div className="carousel-controls">
           
            <button onClick={handleNext} disabled={currentIndex === chatHistory.length - 1}>
            &lt; Prev
            </button>
            <button onClick={handlePrev} disabled={currentIndex === 0}>
               Next  &gt;
            </button>
          </div>
          <div className="carousel">
            <div className="chat-entry">
              <strong>Query:</strong> {chatHistory[currentIndex].user} <br />
              <br />
              <strong>Alaap:</strong> {renderText(chatHistory[currentIndex].assistant)}
            </div>
          </div>
        </>
      ) : (
        <p>No chat history available.</p>
      )}
    </div>
  );
};

export default History;
