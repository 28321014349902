// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <p className="powered-by">
        Powered by <a href="https://codepackers.com">Codepackers</a>
      </p>
    </footer>
  );
};

export default Footer;
