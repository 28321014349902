import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GoogleGenerativeAI } from "@google/generative-ai";

const HindiSpeechRecognition = () => {
  const [transcript, setTranscript] = useState('');
  const [response, setResponse] = useState('');
  const [recognition, setRecognition] = useState(null);
  const genAI = new GoogleGenerativeAI("AIzaSyDmVkUrKZMOfAqy_hq1A1Qv7mrbNzYBdL8"); // Consider moving this to an environment variable.

  useEffect(() => {
    // Initialize SpeechRecognition
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recog = new SpeechRecognition();
      recog.continuous = true;
      recog.lang = 'hi-In';

      recog.onresult = (event) => {
        const current = event.resultIndex;
        const newTranscript = event.results[current][0].transcript;
        setTranscript((prev) => prev + ' ' + newTranscript);
      };

      recog.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
      };

      setRecognition(recog);

      return () => {
        recog.stop();
        recog.onresult = null; // Cleanup on unmount
        recog.onerror = null; // Cleanup on unmount
      };
    } else {
      console.error('SpeechRecognition API is not supported in this browser');
    }
  }, []);

  const startListening = () => recognition && recognition.start();
  const stopListening = () => recognition && recognition.stop();

  const handleSubmit = async () => {
    if (transcript.trim()) {
      const prompt2 = `
      Given the following user input:

      \`${transcript}\`

Please generate a JSON response based on the provided input and return a valid JSON output.

JSON Response Format:

json

{
  "name": "string",
  "email": "string",
  "password": "string"
}

Constraints:

    Mandatory Fields: Ensure that the "name" and "email" fields are always present in the JSON response, even if the input is missing these values. If these fields are not provided, populate them with empty strings.

    Optional Fields: Include the "password" field in the JSON response only if it is explicitly provided in the input.

    Strict Formatting: The JSON output must comply with strict formatting standards, ensuring proper indentation and syntax.

    Data Validation:
        Exclude fields if the input contains invalid data types or formats.
        The "email" field must be in the format [username]@[domain]. If the input contains spaces or improper formatting, modify the email:
            Remove spaces within the local part.
            Concatenate separate name parts into one valid username (convert to lowercase).
            Replace words like "at the rate" or similar with @.

    Name Extraction: Ensure that the "name" field is properly parsed, excluding any unrelated words.

Transformation Rules for Email:

    Remove spaces and concatenate components of the email username.
    Ensure the output uses lowercase for emails.
    Examples of transformations:
        Input: "{first_name} {last_name} 3@gmail.com" → Output: "{first_name}{last_name}@gmail.com"
        Input: "{first_name} {middle_name} {last_name}@gmail.com" → Output: "{first_name}{middle_name}{last_name}@gmail.com"
        Input: "I am {first_name} {last_name} at the rate avengers.com" → Output: "iam{first_name}{last_name}@avengers.com"

Example Inputs and Expected Outputs:

Example 1: Input:

python

my name is {first_name} {last_name} and email id is  {first_name} {last_name} @ gmail.com

Expected Output:

json

{
  "name": "{first_name} {last_name}",
  "email": "{first_name}{last_name}@gmail.com"
}

Example 2: Input:
email id is {first_name} {last_name} @gmail.com

Expected Output:

json

{
  "email": "{first_name}{last_name}@gmail.com"
}


Example 3: Input:

email id is {first_name} {middle_name} {last_name} @ gmail.com

Expected Output:

json

{
  "email": "{first_name}{middle_name}{last_name}@gmail.com"
}



Ensure that your response adheres to these rules and outputs a valid, well-formatted JSON response.

`;


const prompt= `
> निम्नलिखित उपयोगकर्ता इनपुट दिया गया है:

> \`${transcript}\`

> कृपया दिए गए इनपुट के आधार पर JSON प्रतिक्रिया उत्पन्न करें।

> *JSON प्रतिक्रिया प्रारूप:*

> json
> {
> "name": "string",
> "email": "string",
> "password": "string"
> }
> 

> *बाधाएँ:*

> 1. *अनिवार्य फ़ील्ड:* सुनिश्चित करें कि JSON प्रतिक्रिया में "name" और "email" फ़ील्ड हमेशा मौजूद हों, भले ही इनपुट में ये मान न हों। ऐसे मामलों में, उन्हें खाली स्ट्रिंग से भरें।
> 2. *वैकल्पिक फ़ील्ड:* "पासवर्ड" फ़ील्ड को JSON प्रतिक्रिया में केवल तभी शामिल किया जाना चाहिए जब इसे इनपुट में स्पष्ट रूप से प्रदान किया गया हो।

> 3. *सख्त फ़ॉर्मेटिंग:* JSON आउटपुट को उचित इंडेंटेशन और सिंटैक्स के साथ सख्त फ़ॉर्मेटिंग मानकों का पालन करना चाहिए।

> 4. *डेटा सत्यापन:* यदि प्रदान किए गए इनपुट में अमान्य डेटा प्रकार या फ़ॉर्मेट हैं, तो संबंधित फ़ील्ड को JSON प्रतिक्रिया से हटा दिया जाना चाहिए। उदाहरण के लिए, यदि "ईमेल" फ़ील्ड में अमान्य ईमेल पता है, तो इसे शामिल नहीं किया जाना चाहिए।

> 5. *विशेष स्थिति:* यदि इनपुट में केवल "name" प्रदान किया गया है, तो JSON प्रतिक्रिया में केवल "name" कुंजी होनी चाहिए। यदि केवल "email" प्रदान किया गया है, तो JSON प्रतिक्रिया में केवल "email" कुंजी होनी चाहिए। यदि दोनों प्रदान किए गए हैं, तो JSON प्रतिक्रिया में "name" और "email" दोनों कुंजी होनी चाहिए।

*उदाहरण इनपुट:*

मेरा नाम जॉन डो है
मेरी ईमेल johndoe@example.com है
मुझे secret123 पासवर्ड रखना है

*अपेक्षित आउटपुट:*


{
  "name": "John Doe",
  "email": "johndoe@example.com",
  "password": "secret123"
}

*एक और उदाहरण इनपुट:*

ईमेल है janedoe@example.com
पासवर्ड है strong_password

*अपेक्षित आउटपुट:*


{
  "email": "janedoe@example.com",
  "password": "strong_password"
}

*तीसरा उदाहरण इनपुट:*

मेरा नाम ऐलेक्स है

*अपेक्षित आउटपुट:*


{
  "name": "Alex"
}

`
      try {
        const model = genAI.getGenerativeModel({ model: "gemini-pro" });
        const result = await model.generateContent(prompt2);
        
        // Check if the response is structured correctly
        if (result && result.response) {
          setResponse(result.response.text || "No text found in response.");
        } else {
          setResponse("Unexpected response format.");
        }
      } catch (error) {
        console.error('Error fetching response from Ola Kutrim API:', error);
      }
    }
  };

  if (!recognition) {
    return <p>Your browser does not support the SpeechRecognition API.</p>;
  }

  return (
    <div>
      <button onClick={startListening}>Start Listening (Hindi)</button>
      <button onClick={stopListening}>Stop Listening</button>
      <button onClick={handleSubmit}>Submit to Gemini</button>
      <p>Transcript: {transcript}</p>
      <p>Response: {response}</p>
    </div>
  );
};

export default HindiSpeechRecognition;
