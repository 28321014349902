import axios from "axios";
import { useContext } from 'react';
import { AppContext } from '../context/AppContext'; // Adjust the path to your context

const BASE_URL = process.env.REACT_APP_BASE_URL || "https://3.6.69.216/";

// Create a custom hook for API initialization
export const useApi = () => {
    const { changeComponent } = useContext(AppContext);

    // Create an Axios instance
    const api = axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    // Interceptor to add Authorization header
    api.interceptors.request.use((config) => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    // Interceptor to handle common error responses
    api.interceptors.response.use((response) => {
        return response;
    }, async (error) => {
        const status = error.response?.status;

        if (status === 401 || status === 403) {
            console.error(`${status} - Logging out`);
            // Clear stored tokens and user data
            localStorage.removeItem("accessToken");
            localStorage.removeItem("user");
            // Change the component to Home
            changeComponent('login');
            console.log("User has been logged out.");
        } else {
            console.error('API Error:', error.response || error.message);
        }

        return Promise.reject(error);
    });

    return api;
};
