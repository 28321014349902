import React from 'react';
import Footer from './Footer';

const PromoSection = ({ changeComponent, token }) => {
  // Handle button click to navigate based on token presence
  const handleButtonClick = () => {
    if (token) {
      changeComponent('Dashboard');  // Redirect to dashboard if token is present
    } else {
      changeComponent('signup');  // Redirect to signup if no token is found
    }
  };

  return (
    <div className="promo-container">
      {/* First Promo Block */}
      <div className="promo-item right-aligned">
        <img
          src={require('../assets/image_15.png')}  
          alt="Driving promo"
          className="promo-image"
        />
      </div>

      <div className="promo-item">
        <div className="promo-text">
          <p>Ask Alaap</p>
          <h2>Your AI Friend</h2>
          <div className="promo-buttons">
            {/* Button triggers handleButtonClick, which checks token and redirects */}
            <button className="primary-btn" onClick={handleButtonClick}>
              Try It Now
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PromoSection;
