import React, { useState, useCallback, useRef, useEffect } from 'react';
import axios from 'axios';
import AudioRecorder2 from './AudioRecorder2';
import ResultsDisplay from './ResultsDisplay';
import '../App.css';
import History from './History';
import { useApi } from '../middleware/ApiMiddleware'; // Assuming you are using this for API calls
import Footer from './Footer';

const MainComponent = ({ client_id }) => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stopPlayingAudio, setStopPlayingAudio] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [isplaying, setIsPlaying] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);  // State to store chat history
  const abortControllerRef = useRef(null);
  const api = useApi();   // Ref to store the AbortController

  // Fetch initial chat history from the history API
  const fetchInitialHistory = useCallback(async () => {
    try {
      const response = await api.get('api/process/history/');
      
      if (response.data.success) {
        setChatHistory(response.data.data.chat); // Set the initial chat history
      } else {
        console.error("Failed to fetch chat history");
      }
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  }, []);

  // Fetch chat history on component mount
  useEffect(() => {
    fetchInitialHistory();
  }, [fetchInitialHistory]);

  const processAudio = useCallback(async (blob) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('file', blob, 'recording.wav');
    formData.append('query', 'recording.wav');

    abortControllerRef.current = new AbortController(); // Create a new AbortController

    try {
      const response = await api.post('api/process/alaap/v1_1/', formData, {
        signal: abortControllerRef.current.signal, 
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`, 
        }
      });
  
      const links = [];
      if (response.data.data.TTS) {
        for (let i = 1; i <= response.data.data.TTS.count; i++) {

          let link = `${response.data.data.host}static_src/${response.data.data.TTS.filename}${i}.wav?nocache=${new Date().getTime()}`;
          // let link = `${response.data.data.host}static/${response.data.data.TTS.filename}${i}.wav?nocache=${new Date().getTime()}`;

          links.push(link);
        }
      }
      
      const print_data = {
        ttsText: response.data.data.ASR,
        error: response.data.data.error,
        translatedQuery: response.data.data.TRA,
        llmText: response.data.data.LLM,
        translatedAnswer: response.data.data.TRA2,
        sttAudioUrl: response.data.data.TTS,
        hostURL: response.data.data.host,
        links: links,
      };

      setResults(print_data);

      // Append the new conversation to chatHistory
      const newHistoryEntry = {
        user: response.data.data.ASR !== "ERROR" ? response.data.data.ASR : "recording.wav",  // User input from ASR
        assistant: response.data.data.LLM ? response.data.data.LLM : "ERROR"  // Assistant's response from LLM
      };
      setChatHistory((prevHistory) => [newHistoryEntry, ...prevHistory]);  // Update chat history with the new entry

    } catch (error) {
      if (axios.isCancel(error)) {
        // Request was canceled
      } else {
        console.error('Error processing audio:', error);
      }
    } finally {
      setLoading(false);
      setStopPlayingAudio(false);  // Allow new recordings once processing is complete
    }
  }, []);

  const handleRecordingComplete = useCallback((blob) => {
    setResults(null);  // Reset the results
    setStopPlayingAudio(true);  // Stop all playing audio
    processAudio(blob);
  }, [processAudio]);

  const handleReRecord = useCallback(() => {
    setStopPlayingAudio(true);  // Reset the stopPlayingAudio state when re-recording
    setIserror(false);
  }, []);

  const handleError = useCallback(() => {
    setIserror(true);
    // Reset iserror to false after 5 seconds
    setTimeout(() => {
      setIserror(false);
    }, 5000);
  }, []);

  const handlestartplaying = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const handelstopplaying = useCallback(() => {
    setIsPlaying(false);
  }, []);

  // Function to abort the API request
  const handleAbortRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();  // Abort the request
      setLoading(false);  // Set loading to false after aborting
    }
  };

  return (
    <div className="app">
      <h1>{client_id}</h1> 
      <div className='message'>
        <p className='error'>{iserror ? "Error, Please try again" : ""}</p>
        <p>{loading ? "Wait. Processing" : isplaying ? "Playing response.." : ""}</p>
      </div>

      {/* Audio Recorder Component */}
      <AudioRecorder2 
        onRecordingComplete={handleRecordingComplete} 
        onReRecord={handleReRecord}  // Pass the new function to AudioRecorder
        disabled={loading}
        isplaying={isplaying}
        iserror={iserror}
        error={handleError}
        stopRequest={handleAbortRequest} 
      />

      {/* Display results when available */}
      {results && (
        <ResultsDisplay 
          results={results ? results : ""}
          stopPlayingAudio={stopPlayingAudio}
          handleMainError={handleError}
          handlestartplaying={handlestartplaying}
          handelstopplaying={handelstopplaying}
        />
      )}

      {/* Pass chatHistory to History component */}
      <History 
        key={chatHistory.length}  // This key will force re-render on each new entry
        chatHistory={chatHistory} 
        latestIndex={0} 
      />

      <Footer />
    </div>
  );
};

export default MainComponent;
