import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../middleware/ApiMiddleware'; 
import { NavLink } from 'react-router-dom';

const VerticalNavBar = ({ changeComponent, activeComponent }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(window.innerWidth > 768);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [email, setEmail] = useState(''); // New state for email
  const navRef = useRef(null);
  const api = useApi(); 

  useEffect(() => {
    // Retrieve and parse the user data from local storage
    const userData = localStorage.getItem('user');
    if (userData) {
      const parsedUser = JSON.parse(userData);
      setOrgName(parsedUser.org_name || 'Organization');
      setEmail(parsedUser.email || 'User'); // Set email if available
    }

    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(true);
        setIsMobile(false);
      } else {
        setIsOpen(false);
      }
    };

    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target) && window.innerWidth < 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = (component) => {
    changeComponent(component);
    setIsOpen(false);
    console.log("Active component set to:", component);
  };

  const toggleMenu = () => {
    if (window.innerWidth <= 768) {
      setIsOpen(!isOpen);
      setIsMobile(true);
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    setIsMobile(false);
  };

  const toggleSettingsDropdown = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const handleLogout = async () => {
    try {
      const response = await api.get('api/process/logout/');
      localStorage.removeItem('accessToken');
      changeComponent("login");
    } catch (error) {
      console.error('Error Logging Out:', error);
    }
  };

  return (
    <div>
      {!isOpen && (
        <div className="hamburger" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      )}

      <div className={`vertical-nav ${isOpen ? 'open' : ''}`} ref={navRef}>
        {isOpen && (
          <div className="logo-container" onClick={() => handleClick('Home')}>
            <img 
              src={require('../assets/Frame_1.png')} 
              alt="Alaap Logo" 
              className="logo-image" 
            />
            <span className="logo-text">Alaap</span>
            <a className="close-button" onClick={closeMenu}>
              &times;
            </a>
          </div>
        )}
{/* <div className="organization">
          <NavLink activeClassName="active-link">{orgName}</NavLink>
        </div> */}
        <nav className="nav-links">
          <button
            onClick={() => handleClick('Dashboard')}
            className={activeComponent === 'Dashboard' ? 'active-link' : ''}
          >
            <i className="fa-solid fa-house"></i> Dashboard
          </button>

          <button
            onClick={() => handleClick('File')}
            className={activeComponent === 'File' ? 'active-link' : ''}
          >
            <i className="fa-solid fa-file"></i> File
          </button>

          <button onClick={handleLogout}>
            <i className="fas fa-sign-out-alt"></i> Logout
          </button>

          <div className="user-section">
            <div className="user-avatar"></div>
            <div className="user-name">{email}</div> {/* Display email here */}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default VerticalNavBar;
