// src/context/AppContext.js
import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export function AppProvider({ children }) {
  const [activeComponent, setActiveComponent] = useState('Home');

  const changeComponent = (newComponent) => {
    setActiveComponent(newComponent);
  };

  return (
    <AppContext.Provider value={{ activeComponent, changeComponent }}>
      {children}
    </AppContext.Provider>
  );
}
