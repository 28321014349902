import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = ({changeComponent}) => {
  const navigate = useNavigate();

  return (
    <div className="header header-shadow">
      <div className="main_header__center">
      <div className="logo-container" onClick={() => changeComponent('Home')}>
          <span className="logo-text">Alaap</span>
        </div>
      </div>
      <div className="header__right">
        <i className="fa-solid fa-microphone header__profileIcon" onClick={() => changeComponent('Dashboard')} ></i>  {/* Font Awesome Profile Icon */}
      </div>
    </div>
  );
}

export default Header;
