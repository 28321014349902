import React, { useState, useRef, useEffect } from 'react';
import { useApi } from '../middleware/ApiMiddleware'; 

const FileUploadComponent = () => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [newFile, setNewFile] = useState(null);
    const [noFilesFound, setNoFilesFound] = useState(false);
    const api = useApi(); 
    const fileInputRef = useRef(null);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await api.get('api/process/config/document/upload/v2/');
                if (response.status === 200 && response.data.success) {
                    const files = response.data.data || [];
                    if (files.length > 0) {
                        const formattedFiles = files.map(file => ({
                            id: file.id,
                            name: file.file_name,
                            size: 'Unknown',
                            time: new Date(file.create_time).toLocaleString(),
                            ragie_document_id: file.ragie_document_id
                        }));
                        setUploadedFiles(formattedFiles);
                        setNoFilesFound(false);
                    } else {
                        setNoFilesFound(true);
                    }
                } else {
                    setNoFilesFound(true);
                }
            } catch (error) {
                console.error("Failed to fetch files", error);
                setNoFilesFound(true);
            }
        };

        fetchFiles();
    }, []);

    const handleFileChange = (e) => {
        setNewFile(e.target.files[0]);
        setUploadSuccess(false);  // Reset upload success on file change
    };

    const handleUpload = async () => {
        if (!newFile) {
            fileInputRef.current.click();
            return;
        }

        setUploading(true);
        const formData = new FormData();
        formData.append('file', newFile);

        try {
            const response = await api.post('api/process/config/document/upload/v2/', formData);
            if (response.status === 200) {
                const uploadedFile = {
                    id: Date.now(),
                    name: newFile.name,
                    size: `${(newFile.size / 1024).toFixed(1)} KB`,
                    time: 'Just now'
                };
                setUploadedFiles([uploadedFile, ...uploadedFiles]);
                setUploadSuccess(true);
                setNoFilesFound(false);
            }
        } catch (error) {
            console.error("Upload failed", error);
        } finally {
            setUploading(false);
            setNewFile(null);  // Reset newFile to allow selecting another file
        }
    };

    const handleDelete = async (ragieDocumentId) => {
        try {
            const response = await api.delete(`api/process/config/document/upload/v2/${ragieDocumentId}/`);
            if (response.status === 200) {
                const updatedFiles = uploadedFiles.filter(file => file.ragie_document_id !== ragieDocumentId);
                setUploadedFiles(updatedFiles);
                setNoFilesFound(updatedFiles.length === 0);
            }
        } catch (error) {
            console.error("Delete failed", error);
        }
    };

    return (
        <div className="file-upload-container">
            <div className="upload-box">
                <i className="fa-solid fa-upload upload-icon"></i>
                <input 
                    type="file" 
                    onChange={handleFileChange} 
                    ref={fileInputRef} 
                    hidden 
                />
                {newFile && <p>Selected File: {newFile.name}</p>}
                <button 
                    onClick={handleUpload} 
                    disabled={uploading} 
                    className="upload-btn"
                >
                    {uploading ? 'Uploading...' : 'Upload'}
                </button>
                {uploadSuccess && (
                    <p className="upload-success-message">Upload complete! You can upload another file.</p>
                )}
                <div className="file-format-info">
                    <span>PDF</span> <span>DOCX</span> <span>TXT</span> <span>&lt; 10 MB</span>
                </div>
            </div>

            <div className="uploaded-files-section">
                <h4>Uploaded Files</h4>
                {noFilesFound ? (
                    <p>No files found</p>
                ) : (
                    <ul className="uploaded-files-list">
                        {uploadedFiles.map((file, index) => (
                            <li key={file.id || index} className="uploaded-file-item">
                                <i className="fa-solid fa-file-pdf pdf-icon"></i>
                                <div className="file-info">
                                    <p>{file.name}</p>
                                    <span>{file.time}</span>
                                </div>
                                <button 
                                    className="delete-btn" 
                                    onClick={() => handleDelete(file.ragie_document_id)}
                                >
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default FileUploadComponent;
