import React from 'react';
import { useNavigate } from 'react-router-dom';

const HeaderHome = ({ changeComponent, activeComponent }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('accessToken'); // Check for token in localStorage

  return (
    <div className="header">
      <div className="main_header__center">
        <div className="logo-container" onClick={() => changeComponent('Home')}>
          {/* <img 
            src={require('../assets/cplogo.jpg')} 
            alt="Alaap Logo" 
            className="logo-image"
          /> */}
          <span className="logo-text">Alaap</span>
        </div>
      </div>
      <div className="header__right">
        {activeComponent === 'Home' && !token && (  
          <button onClick={() => changeComponent('login')} className="btn-login">Sign In</button>
        )}
      </div>
    </div>
  );
};

export default HeaderHome;
