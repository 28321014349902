import React, { useEffect, useState } from 'react';
import api from '../middleware/ApiMiddleware';
import { useNavigate } from 'react-router-dom';
import MainComponentV1 from './MainComponentV1'; 


const VoiceBot = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(null);       

    // const fetchData = async () => {
    //     try {
    //         const response = await api.get('api/process/auth/', { navigate });
            
    //         if (response.data.success) {
    //             console.log(response.data.data.chat); // Log chat history for debugging
    //             setPage(response.data.data.org);  
    //         } else {
    //             console.error("Failed to verify Auth");
    //             return [];
    //         }
    //     } catch (error) {
    //         console.error('Error verify Auth:', error);
    //         return [];
    //     }

    // };

    // useEffect(() => {
    //     fetchData();
    // }, []);

    useEffect(() => {
        if (page) {
            document.title = page;  
        }
        return () => {
            document.title = "Default Title";  
        };
    }, [page]);

    return (
        <>
            <MainComponentV1 client_id={page} />  {/* Pass the org name as client_id */}
        </>
    );
}

export default VoiceBot;
